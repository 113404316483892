import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'Business',
      },
      {
        color: 'warning',
        label: 'Family',
      },
      {
        color: 'success',
        label: 'Holiday',
      },
      {
        color: 'info',
        label: 'ETC',
      },
    ],
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      
      return new Promise((resolve, reject) => {
        
        let newData = [];
         axios.get(process.env.VUE_APP_API_HOST + '/jobs', { }).then((response) => {
           let rows = response.data;    
           for(let i=0;i<rows.length;i++)      
           {
             var thisSet= { 
               id: 1,
               url: '',
               title: '',
               start: '',
               end: '',
               allDay: false,
               extendedProps: {
                 calendar: 'Business',
               },
             };
             thisSet.id = rows[i].job_id;
             thisSet.title = rows[i].job_address;
             thisSet.url = "/apps/jobs/edit/"+rows[i].job_no;
             thisSet.start = new Date(rows[i].job_start_date);
             thisSet.end = new Date(rows[i].job_end_date);
             thisSet.end.setDate(thisSet.end.getDate() + 1);
             newData.push(thisSet);
           }
           resolve(newData) 
         });
      })
    },
   
  },
}
